import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';

export const ShowsPageTemplate = ({
  title,
  content,
  posts = [],
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="flex flex-column">
      <div>
        <div>
          <div>
            <div>
              <h1 className="title thearcher">{title}</h1>
              <PageContent className="content" content={content} />
            </div>
            <ul className="list ma0 pa0">
              {posts.map(({ node }) => (
                <li
                  className="flex lh-copy flex-wrap items-center mv4 f3"
                  key={node.frontmatter.title}
                >
                  <strong>{node.frontmatter.date}</strong>
                  <svg
                    className="pa1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="4" />
                  </svg>
                  <span>{node.frontmatter.title}</span>
                  <svg
                    className="pa1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="4" />
                  </svg>
                  <span>{node.frontmatter.location}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

ShowsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.object),
  contentComponent: PropTypes.func,
};

const ShowsPage = ({ data }) => {
  const { markdownRemark: page, allMarkdownRemark: posts } = data;

  return (
    <Layout>
      <ShowsPageTemplate
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        posts={posts && posts.edges}
        content={page.html}
      />
    </Layout>
  );
};

ShowsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ShowsPage;

export const ShowsPageQuery = graphql`
  query ShowsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "show-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY @ hh:mma")
            location
          }
        }
      }
    }
  }
`;
